<!-- 时效设置弹窗 -->
<template>
  <el-dialog
      width="90%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'时效设置':'时效设置'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">
      <!--<el-row :gutter="18">-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="平台接单提醒:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.platform_join_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.platform_join_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="客服响应:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.service_response_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.service_response_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--</el-row>-->
      <!--<el-row :gutter="18">-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="司机接单:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_join_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_join_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="平台派单:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.platform_dispatch_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.platform_dispatch_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--</el-row>-->
      <!--<el-row :gutter="18">-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="司机接单至出发提醒:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_depart_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_depart_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="司机出发至到达提醒:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_arrive_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_arrive_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--</el-row>-->
      <!--<el-row :gutter="18">-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="司机到达至完成提醒:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_accomplish_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_accomplish_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="订单完成至回访提醒:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_return_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.technician_return_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--</el-row>-->
      <!--<el-row :gutter="18">-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="下单至司机接单提醒:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.place_technician_join_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.place_technician_join_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="下单至司机出发提醒:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.place_technician_depart_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.place_technician_depart_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--</el-row>-->
      <!--<el-row :gutter="18">-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="下单至司机到达提醒:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.place_technician_arrive_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.place_technician_arrive_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--  <el-col :sm="12">-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="下单至完成提醒:" prop="nickname">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.place_technician_accomplish_normal"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--    <el-col :span="12">-->
      <!--      <el-form-item label="考核:" prop="nickname" label-width="90px">-->
      <!--        <el-input-->
      <!--            class='kuandu'-->
      <!--            clearable-->
      <!--            :maxlength="20"-->
      <!--            v-model="form.place_technician_accomplish_assess"-->
      <!--            placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--    </el-col>-->
      <!--  </el-col>-->
      <!--</el-row>-->

      <!-- 数据表格 -->
      <my-table
          ref="myTable"
          :columns="columns"
          :tableData="List"
          :cellStyle="cellStyle"
          :headerCellStyle="headerCellStyle"
          :rowClassName="rowClassName"
          :loading="loading1"
      >
        <!--自定义列显示 示例-->
        <template v-slot:biaozhun= "{ scope }">
          <div>
            <el-input
                clearable
                :maxlength="20"
                v-model="scope.row.biaozhun"
                placeholder="请输入">
              <template slot="append">分钟</template>
            </el-input>
          </div>
        </template>

        <template v-slot:kaohe= "{ scope }">
          <div>
            <el-input
                clearable
                :maxlength="20"
                v-model="scope.row.kaohe"
                placeholder="请输入">
              <template slot="append">分钟</template>
            </el-input>
          </div>
        </template>

        <template v-slot:tixing= "{ scope }">
          <div>
            <el-input
                clearable
                :maxlength="20"
                v-model="scope.row.tixing"
                placeholder="请输入">
              <template slot="append">分钟</template>
            </el-input>
          </div>
        </template>

        <template v-slot:neirong= "{ scope }">
          <div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="车牌号"></el-checkbox>
              <el-checkbox label="员工姓名"></el-checkbox>
            </el-checkbox-group>
          </div>
        </template>

        <template v-slot:koukuan= "{ scope }">
          <div>
            <el-input
                clearable
                :maxlength="20"
                v-model="scope.row.koukuan"
                placeholder="请输入">
              <template slot="append">元</template>
            </el-input>
          </div>
        </template>

        <!-- 不使用数据可不接收scope值 -->
        <!--<template v-slot:operationSlot= "{ scope }">-->

        <!--</template>-->

      </my-table>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {Saveservice_aging} from "@/api/custom";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    id:Number,
    pid:Number
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      checkList:[],
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading1:true,
      // 表头数据
      columns: [
        // {
        //   type: "selection",
        //   isShow: true
        // },
        {
          label: "状态",
          // type: "index",
          // width: "70"
          prop: "title",
          isShow: true
        },
        {
          label: "回传时间",
          prop: "biaozhun",
          slot: "biaozhun",
          isShow: true
        },
        {
          label: "提醒时间",
          prop: "tixing",
          slot: "tixing",
          isShow: true
        },
        {
          label: "考核时间",
          prop: "kaohe",
          slot: "kaohe",
          isShow: true
        },
        {
          label: "提醒内容",
          prop: "neirong",
          slot: "neirong",
          isShow: true
        },
        {
          label: "超时扣款",
          prop: "koukuan",
          slot: "koukuan",
          isShow: true
        },
      ],

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    this.loading1 = false;
    let data = {
      title:'受理时间',
      biaozhun:'1',
      kaohe:'3',
      tixing:'2',
      neirong:'',
      koukuan:'5',
    }
    this.List.push(data);

    let data1 = {
      title:'派单时间',
      biaozhun:'2',
      kaohe:'6',
      tixing:'5',
      neirong:'',
      koukuan:'6',
    }
    this.List.push(data1);

    let data2 = {
      title:'服务商接单时间',
      biaozhun:'4',
      kaohe:'8',
      tixing:'6',
      neirong:'',
      koukuan:'5',
    }
    this.List.push(data2);

    let data3 = {
      title:'司机接单时间',
      biaozhun:'6',
      kaohe:'8',
      tixing:'7',
      neirong:'',
      koukuan:'5',
    }
    this.List.push(data3);

    let data4 = {
      title:'司机出发时间',
      biaozhun:'10',
      kaohe:'15',
      tixing:'12',
      neirong:'',
      koukuan:'10',
    }
    this.List.push(data4);

    let data5 = {
      title:'跟单时间',
      biaozhun:'20',
      kaohe:'25',
      tixing:'22',
      neirong:'',
      koukuan:'10',
    }
    this.List.push(data5);

    let data6 = {
      title:'司机到达时间',
      biaozhun:'30',
      kaohe:'35',
      tixing:'32',
      neirong:'',
      koukuan:'30',
    }
    this.List.push(data6);

    let data7 = {
      title:'赶往目的地',
      biaozhun:'40',
      kaohe:'45',
      tixing:'42',
      neirong:'',
      koukuan:'20',
    }
    this.List.push(data7);

    let data8 = {
      title:'完成时间',
      biaozhun:'5',
      kaohe:'55',
      tixing:'52',
      neirong:'',
      koukuan:'10',
    }
    this.List.push(data8);

    let data9 = {
      title:'回访时间',
      biaozhun:'5',
      kaohe:'10',
      tixing:'8',
      neirong:'',
      koukuan:'10',
    }
    this.List.push(data9);

    let data10 = {
      title:'初审时间',
      biaozhun:'10',
      kaohe:'15',
      tixing:'12',
      neirong:'',
      koukuan:'10',
    }
    this.List.push(data10);

    let data11 = {
      title:'严重超时',
      biaozhun:'480',
      kaohe:'470',
      tixing:'465',
      neirong:'',
      koukuan:'200',
    }
    this.List.push(data11);
  },
  methods: {
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // let data = {
          //   // id: this.pid,
          //   config: this.form
          // }
          Saveservice_aging(this.id,this.form).then(res => {
            if(res.code == 200) {
              this.$message.success(res.msg)
              this.loading = true;
              this.updateVisible(false);
              this.$emit('done');
              this.loading = false;
            }else {
              this.$message.error(e.msg)
            }
          }).catch(e => {
            this.$message.error(e.msg)
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped>
  .kuandu{
    width: 140px;
  }
  .marginb{
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
  }
</style>
